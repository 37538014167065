exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-page-10-js": () => import("./../../../src/pages/manifesto/page-10.js" /* webpackChunkName: "component---src-pages-manifesto-page-10-js" */),
  "component---src-pages-manifesto-page-11-js": () => import("./../../../src/pages/manifesto/page-11.js" /* webpackChunkName: "component---src-pages-manifesto-page-11-js" */),
  "component---src-pages-manifesto-page-12-js": () => import("./../../../src/pages/manifesto/page-12.js" /* webpackChunkName: "component---src-pages-manifesto-page-12-js" */),
  "component---src-pages-manifesto-page-13-js": () => import("./../../../src/pages/manifesto/page-13.js" /* webpackChunkName: "component---src-pages-manifesto-page-13-js" */),
  "component---src-pages-manifesto-page-14-js": () => import("./../../../src/pages/manifesto/page-14.js" /* webpackChunkName: "component---src-pages-manifesto-page-14-js" */),
  "component---src-pages-manifesto-page-15-js": () => import("./../../../src/pages/manifesto/page-15.js" /* webpackChunkName: "component---src-pages-manifesto-page-15-js" */),
  "component---src-pages-manifesto-page-16-js": () => import("./../../../src/pages/manifesto/page-16.js" /* webpackChunkName: "component---src-pages-manifesto-page-16-js" */),
  "component---src-pages-manifesto-page-17-js": () => import("./../../../src/pages/manifesto/page-17.js" /* webpackChunkName: "component---src-pages-manifesto-page-17-js" */),
  "component---src-pages-manifesto-page-18-js": () => import("./../../../src/pages/manifesto/page-18.js" /* webpackChunkName: "component---src-pages-manifesto-page-18-js" */),
  "component---src-pages-manifesto-page-19-js": () => import("./../../../src/pages/manifesto/page-19.js" /* webpackChunkName: "component---src-pages-manifesto-page-19-js" */),
  "component---src-pages-manifesto-page-2-js": () => import("./../../../src/pages/manifesto/page-2.js" /* webpackChunkName: "component---src-pages-manifesto-page-2-js" */),
  "component---src-pages-manifesto-page-20-js": () => import("./../../../src/pages/manifesto/page-20.js" /* webpackChunkName: "component---src-pages-manifesto-page-20-js" */),
  "component---src-pages-manifesto-page-21-js": () => import("./../../../src/pages/manifesto/page-21.js" /* webpackChunkName: "component---src-pages-manifesto-page-21-js" */),
  "component---src-pages-manifesto-page-22-js": () => import("./../../../src/pages/manifesto/page-22.js" /* webpackChunkName: "component---src-pages-manifesto-page-22-js" */),
  "component---src-pages-manifesto-page-23-js": () => import("./../../../src/pages/manifesto/page-23.js" /* webpackChunkName: "component---src-pages-manifesto-page-23-js" */),
  "component---src-pages-manifesto-page-24-js": () => import("./../../../src/pages/manifesto/page-24.js" /* webpackChunkName: "component---src-pages-manifesto-page-24-js" */),
  "component---src-pages-manifesto-page-3-js": () => import("./../../../src/pages/manifesto/page-3.js" /* webpackChunkName: "component---src-pages-manifesto-page-3-js" */),
  "component---src-pages-manifesto-page-4-js": () => import("./../../../src/pages/manifesto/page-4.js" /* webpackChunkName: "component---src-pages-manifesto-page-4-js" */),
  "component---src-pages-manifesto-page-5-js": () => import("./../../../src/pages/manifesto/page-5.js" /* webpackChunkName: "component---src-pages-manifesto-page-5-js" */),
  "component---src-pages-manifesto-page-6-js": () => import("./../../../src/pages/manifesto/page-6.js" /* webpackChunkName: "component---src-pages-manifesto-page-6-js" */),
  "component---src-pages-manifesto-page-7-js": () => import("./../../../src/pages/manifesto/page-7.js" /* webpackChunkName: "component---src-pages-manifesto-page-7-js" */),
  "component---src-pages-manifesto-page-8-js": () => import("./../../../src/pages/manifesto/page-8.js" /* webpackChunkName: "component---src-pages-manifesto-page-8-js" */),
  "component---src-pages-manifesto-page-9-js": () => import("./../../../src/pages/manifesto/page-9.js" /* webpackChunkName: "component---src-pages-manifesto-page-9-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

